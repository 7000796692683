<template>
  <div class="jinengDialog">
    <el-dialog
      :visible.sync="dialogVisible"
      width="750px"
      :show-close="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <!-- vue-cropper与element ui实现头像图片剪切上传   未做 -->
        <div class="wb-tit font18">
          <div class="wb-left">选择工作技能</div>
          <div class="wb-right">
            <el-button type="primary" @click="submitForm">确定</el-button>
          </div>
        </div>
        <div class="" style="background-color: #ffffff">
          <el-cascader-panel
            :options="skillDataOptions"
            :props="{
              expandTrigger: 'click',
              multiple: true,
              checkStrictly: true,
              emitPath: true,
            }"
            v-model="selectList"
            @change="handleChange"
          >
          </el-cascader-panel>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      skillDataOptions: [],
      itSkills:[],
      chipSkills:[],
      skillType:0,  // 技能类型，默认0-IT相关技能, 1-芯片相关技能
      selectSkills: [],
      selectList: [
        // [2501, 2502],
        // [2501, 2502, 2520],
      ],
      selectItemList: [],
      callback: null,
    };
  },
  created() {
    this.getSkillList("skill");
    this.getSkillList("chip_skill");
  },
  mounted() {
    // 展开 IT 相关技能列表
    Bus.$on("itSkillStatus", (data) => {
      // console.log("$skill page mounted.itSkillStatus ---- $on, data = ", data);
      this.skillDataOptions = [];
      this.dialogVisible = data.show;
      this.callback = data.callback;

      this.skillType = 0;
      this.skillDataOptions = this.itSkills;

      let defaultData = [];
      if (data.skillIds && data.skillIds.length > 0) {
        data.skillIds.map((e) => {
          defaultData.push(parseInt(e + ""));
        });

        let tmp = [];
        tmp = this.selectedList(0, defaultData);
        this.selectList = tmp;

      } else {
        this.selectList = [];
      }
      // console.log("$skill page mounted.itSkillStatus ---- selected = ", this.selectList);
    });
    // 展开 非IT 相关技能列表
    Bus.$on("nonTechStatus", (data) => {
      this.dialogVisible = data.show;
      this.callback = data.callback;
      this.skillType = 2;
      // this.skillDataOptions = this.chipSkills;
      // console.log("$non tech skill page mounted ---- $on, data = ", data);
      // TODO 非IT销售类 技能弹窗处理
    });

    // 展开 芯片 相关技能列表
    Bus.$on("chipSkillStatus", (data) => {
      this.skillDataOptions = [];
      this.dialogVisible = data.show;
      this.callback = data.callback;

      this.skillType = 1;
      this.skillDataOptions = this.chipSkills;
      // console.log("$chip skill page mounted.chipSkillStatus ---- $on, data = ", data);

      let defaultData = [];
      if (data.skillIds && data.skillIds.length > 0) {

        data.skillIds.forEach((e) => {
          defaultData.push(parseInt(e + ""));
        });

        let tmp = [];
        tmp = this.selectedList(1, defaultData);
        this.selectList = tmp;

      } else {
        this.selectList = [];
      }
      // console.log("$chip skill page mounted.chipSkillStatus ---- selected = ", this.selectList);
    });
  },
  watch: {
    selectList(value) {
      //   for (let i = 0; i < value.length; i++) {
      //     let isDelete = true;
      //     /// 第2级
      //     if (value[i].length == 2) {
      //       /// 遍历所有 验证二级是否取消
      //       for (let m = 0; m < value.length; m++) {
      //         if (value[m].length == 1) {
      //           if (value[m][0] == value[i][0]) {
      //             isDelete = false;
      //           }
      //         }
      //       }
      //       /// 取消 删除
      //       if (isDelete) {
      //         // var filtered = value.filter(function (value, index) {
      //         //   return index != i;
      //         // });
      //         value = value.filter(function (value, index) {
      //           return index != i;
      //         });
      //       }
      //     }
      //   }

      for (let i = 0; i < value.length; i++) {
        let isDelete = true;

        /// 第3级
        if (value[i].length == 3) {
          /// 遍历所有 验证二级是否取消
          for (let m = 0; m < value.length; m++) {
            if (value[m].length == 2) {
              if (value[m][1] == value[i][1]) {
                isDelete = false;
              }
            }
          }
          /// 取消 删除
          if (isDelete) {
            // var filtered = value.filter(function (value, index) {
            //   return index != i;
            // });
            value = value.filter(function (value, index) {
              return index != i;
            });
          }
        }
      }
      this.selectList = value;

      /// 设置selectItemList
      let tmp = [];
      this.selectList.forEach((e) => {
        if (e.length == 2) {
          this.skillDataOptions.forEach((a1) => {
            if (a1.children.length > 0) {
              a1.children.forEach((a2) => {
                if (a2.value == e[1]) {
                  tmp.push(a2);
                }
              });
            }
          });
        }
        if (e.length == 3) {
          this.skillDataOptions.forEach((a1) => {
            if (a1.children.length > 0) {
              a1.children.forEach((a2) => {
                if (a2.children.length > 0) {
                  a2.children.forEach((a3) => {
                    if (a3.value == e[2]) {
                      tmp.push(a3);
                    }
                  });
                }
              });
            }
          });
        }
      });
      this.selectItemList = tmp;
    },
  },
  methods: {
    getSkillList(code) {
      //获取所有技能
      // this.$api.getdict("get", { code: "skill" }).then((res) => {
      this.$api.getSkillList("get", { code: code }).then((res) => {
        if (res.data === null || res.data.length === 0) {
          this.$message({
            message: "获取技能列表失败！ "+ res.message,
            type: "error",
          });
          return;
        }
        let tmpSkillList = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
            code: item.code,
            children: [],
          };
          if (item.list && item.list.length) {
            item.list.map((sub) => {
              let subObj = {
                value: sub.id,
                label: sub.name,
                code: item.code,
                children: [],
              };
              if (sub.list && sub.list.length) {
                sub.list.map((list) => {
                  let listObj = {
                    value: list.id,
                    label: list.name,
                    code: item.code,
                  };
                  subObj.children.push(listObj);
                });
              }
              obj.children.push(subObj);
            });
          }
          tmpSkillList.push(obj);
        });
        if(code === "chip_skill"){
          this.chipSkills = tmpSkillList;
        } else {
          this.itSkills = tmpSkillList;
        }
      });
    },
    // 筛选选中列表
    selectedList(type, defaultData){
      let tmp = [];
      let list = [];

      type === 1 ? list = this.chipSkills : type === 0 ? list = this.itSkills : list = [];

      list.forEach((a1) => {
        if (a1.children.length > 0) {
          let isExist1 = false;
          a1.children.forEach((a2) => {
            if (defaultData.indexOf(a2.value) !== -1) {
              // console.log("skillIds", a2);

              tmp.push([a1.value, a2.value]);
              isExist1 = true;
            }
            if (a2.children.length > 0) {
              a2.children.forEach((a3) => {
                if (defaultData.indexOf(a3.value) !== -1) {
                  // console.log("skillIds", a3);

                  tmp.push([a1.value, a2.value, a3.value]);
                  isExist1 = true;
                }
              });
            }
          });
          if (isExist1) {
            tmp.push([a1.value]);
          }
        }
      });
      list.forEach((b1) => {
        if (b1.children.length > 0) {
          let isExist2 = false;
          b1.children.forEach((b2) => {
            if (defaultData.indexOf(b2.value) !== -1) {
              // console.log("skillIds", b2);

              tmp.push([b1.value, b2.value]);
              isExist2 = true;
            }
            if (b2.children.length > 0) {
              b2.children.forEach((b3) => {
                if (defaultData.indexOf(b3.value) !== -1) {
                  // console.log("skillIds", b3);

                  tmp.push([b1.value, b2.value, b3.value]);
                  isExist2 = true;
                }
              });
            }
          });
          if (isExist2) {
            tmp.push([b1.value]);
          }
        }
      });
      // console.log("$skill type = ", type, ", ---- page mounted ---- $on.selected, selectedList = ", tmp);
      return tmp;
    },
    handleChange(value) {
      this.selectSkills = [];
      this.skillDataOptions.map((item) => {
        if (item.value == value[0]) {
          item.children.map((sub) => {
            if (sub.value == value[1]) {
              this.selectSkills.push(sub);
              if (sub.children && sub.children.length) {
                sub.children.map((list) => {
                  if (list.value == value[2]) {
                    this.selectSkills.push(list);
                  }
                });
              }
            }
          });
        }
      });
    },
    submitForm() {
      this.callback(this.selectItemList);
      this.dialogVisible = false;
    },
    closebox() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.el-cascader-menu {
  width: 33.33% !important;
}
.el-scrollbar__wrap {
  overflow: auto !important;
}
.el-scrollbar__wrap::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  height: 1px;
}
.el-scrollbar__wrap::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}
.el-cascader-node__postfix {
  position: relative;
  right: 0;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 20px 30px;
    background-color: #ffffff;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wb-left {
      color: #111111;
    }
    .wb-right {
      /deep/ .el-button {
        border-radius: 0px;
        width: 104px;
        height: 44px;

        background: #00bcff;
        border: none;
      }
    }
  }
}

.c-main {
  display: flex;

  min-height: 400px;
  .cm-left {
    width: 33%;
    background-color: #ffffff;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 18px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .t-active {
      color: #00bcff;
      font-weight: 600;
      background-color: #f8f7fc;
    }
    .t-active:before {
      position: absolute;
      content: "";
      width: 4px;
      height: 28px;
      // top: 0;
      background: #00bcff;
      left: 0px;
      transform: translate(0%, 0%);
    }
  }
  .cm-center {
    width: 33%;
    background-color: #f8f7fc;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .b-active {
      color: #00bcff;
    }
  }
  .cm-right {
    width: 33%;
    background-color: #f2f1f6;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-active {
      color: #00bcff;
    }
  }
}
</style>
