import { render, staticRenderFns } from "./choosejineng.vue?vue&type=template&id=234c19ae&scoped=true&"
import script from "./choosejineng.vue?vue&type=script&lang=js&"
export * from "./choosejineng.vue?vue&type=script&lang=js&"
import style0 from "./choosejineng.vue?vue&type=style&index=0&lang=css&"
import style1 from "./choosejineng.vue?vue&type=style&index=1&id=234c19ae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234c19ae",
  null
  
)

export default component.exports