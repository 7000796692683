<template>
  <div class="">
    <!-- 职位基本信息 -->
    <div class="addposition">
      <div class="a-tit font20">职位基本信息</div>
      <el-form
        :model="postruleForm"
        :rules="postrules"
        ref="postInfoForm"
        label-width="130px"
        class="demo-ruleForm from-box"
      >
        <!-- <el-form-item label="项目周期" class="xmzhouqi">
          <el-date-picker
            type="date"
            placeholder="起"
            v-model="postruleForm.startDate"
            style="width: 184px"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <span style="margin: 0 13px">-</span>
          <el-date-picker
            type="date"
            placeholder="止"
            v-model="postruleForm.endDate"
            style="width: 184px"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item> -->
        <el-form-item label="职位名称：" prop="jobName">
          <el-input
            v-model="postruleForm.jobName"
            placeholder="请输入职位名称，如java开发程序员"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位描述：" prop="jobDescription">
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 10 }"
            show-word-limit
            placeholder="请勿填写QQ、微信、电话等联系方式及特殊符号、性别歧视词、违反劳动 法相关内容，否则有可能会导致您的账号被禁。"
            v-model="postruleForm.jobDescription"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="招聘人数：" prop="jobSize">
          <el-input
            placeholder="请输入招聘人数"
            v-model="postruleForm.jobSize"
            style="width: 400px"
          >
            <template slot="append"> 人 </template>
          </el-input>
        </el-form-item>
        <el-form-item label="工作城市：" prop="city">
          <el-cascader
            v-model="cityCodeed"
            :options="options"
            placeholder=""
            :props="{ expandTrigger: 'hover' }"
            @change="handleChange"
            style="width: 400px">
          </el-cascader>
          <span style="position: absolute; left: 15px; color: #606266"
              v-if="!cityCodeed.length">
            {{ postruleForm.city }}
          </span>
        </el-form-item>
        <el-form-item label="工作地点：" prop="workPlace">
          <el-input
            v-model="postruleForm.workPlace"
            placeholder="请选择工作地点"
            style="width: 400px"
            @click.native="workDialogVisible = true"
          ></el-input>
        </el-form-item>
        <el-form-item label="薪资范围：" prop="salaryHigh">
          <!-- <el-select v-model="postruleForm.gzmax" placeholder="请选择模式" style="width: 126px;margin-right: 30px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <!-- <el-select v-model="postruleForm.gzmin" placeholder="请选择职级" style="width: 126px;margin-right: 30px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <el-select
            v-model="postruleForm.salaryLow"
            placeholder="最低"
            style="width: 126px; margin-right: 30px"
          >
            <el-option
              v-for="item in gzLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="postruleForm.salaryHigh"
            placeholder="最高"
            style="width: 126px"
          >
            <div v-for="item in gzHighOptions" :key="item.value">
              <el-option
                :label="item.label"
                :value="item.value"
                v-if="item.value > postruleForm.salaryLow"
              ></el-option>
            </div>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 职位要求 -->
      <div class="a-tit font20">职位要求</div>
      <el-form
        :model="postruleForm"
        :rules="postrules"
        ref="postNeedForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <div class="from-boxdown">
          <el-form-item label="工作技能：" prop="skillIds">
            <el-select
                v-model="skillType"
                placeholder="请选择技能类型"
                style="width: 210px; margin-right: 30px"
                @change="onSkillTypeChanged"
            >
              <el-option
                  v-for="item in skillTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              :value="getSkillName()"
              placeholder="请选择工作技能"
              style="width: 382px"
              @click.native="projectSkill"
            >
              <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="附加技能：" prop="additionalskill">
						<el-select v-model="postruleForm.additionalskill" placeholder="请选择工作技能" style="width: 210px;">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item> -->
          <br />
          <el-form-item label="学历：" prop="educationType">
            <el-select
              v-model="postruleForm.educationType"
              placeholder="请选择学历"
              style="width: 210px"
            >
              <el-option
                v-for="item in educationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作经验：" prop="expHigh">
            <el-select
              v-model="postruleForm.expLow"
              placeholder="最低"
              style="width: 126px; margin-right: 30px"
            >
              <el-option
                v-for="item in expLowOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="postruleForm.expHigh"
              placeholder="最高"
              style="width: 126px"
            >
              <div v-for="item in expHighOptions" :key="item.value">
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-if="item.value > postruleForm.expLow"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="证书要求：" prop="honorCertNames">
            <el-input
              v-model="postruleForm.honorCertNames"
              placeholder="请输入请输入证书名称"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item label="外语能力：" :rules="languageFlag?[{required: true, message:'请选择语言等级', trigger: 'blur'}]:null">
            <el-select
              v-model="postruleForm.languageType"
              placeholder="请选择语种"
              style="width: 185px; margin-right: 30px"
              @change="onLanguageChanged"
            >
              <el-option
                v-for="item in languageTYpeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="postruleForm.languageLevel"
              placeholder="请选择语言等级"
              style="width: 185px"
            >
              <el-option
                v-for="item in languageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item style="display: none" label="排序：" prop="orderNum">
            <el-input
              v-model="postruleForm.orderNum"
              placeholder="请输入排序"
              style="width: 400px"
            ></el-input>
          </el-form-item>
        </div>
        <!-- @click="submitForm('postruleForm')" -->
        <el-form-item
          ><el-button type="primary" @click="saveJob"
            >发布职位</el-button
          ></el-form-item
        >
      </el-form>
    </div>

    <!-- 发布成功弹窗 -->
    <el-dialog
      :visible.sync="releaseDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="release_box">
        <div class="ub-img">
          <img src="../../assets/images/position/gouChenggong.png" />
        </div>
        <div class="ub-text font22">发布成功</div>
      </div>
    </el-dialog>
    <!-- 工作地点弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="closeworkDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">工作地点</div>
        <div class="frombox">
          <el-form
            :model="workForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="街道/写字楼："
              prop="street"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="housenumber"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="workForm.housenumber"
                placeholder="请输入行政区、街道、写字楼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :key="'marker' + index"
                  :position="marker.position"
                  :events="marker.events"
                  :icon="marker.icon"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="submitForm('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 技能弹窗 -->
    <skill-selector></skill-selector>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";
import { cityDataes } from "../../common/addAddress.js";
import Bus from "../../common/bus.js";
import { options } from "../../common/options.js";
import skillSelector from "./choosejineng";

export default {
  components: {
    skillSelector,
  },
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      cityCodeed: [],
      cityCodeFull: "", //城市编码全部

      skillList: [], // JSON.parse(sessionStorage.getItem("skill")),
      skillFirstActive: 0,
      skillSecondActive: 0,
      skillLastActive: 0,
      skillNames: [],
      skillIds: [],
      skillType: 0,
      skillTypes: options.skillTypes,

      postruleForm: {
        jobType: 2,
        jobName: "",
        jobDescription: "",
        jobSize: "",
        city: "", //城市编码
        workPlace: "",
        salaryLow: "",
        salaryHigh: "",
        startDate: "",
        endDate: "",
        // 职位要求
        skillIds: "",
        educationType: "",
        expYear: "",
        expLow: "",
        expHigh: "",
        honorCertNames: "",
        languageType: "",
        languageLevel: "",
        orderNum: "",
      },
      postrules: {
        endTime: [
          { required: true, message: "请选择项目周期", trigger: "blur" },
        ],
        jobName: [
          {required: true, message: "请输入职位名称", trigger: "blur"},
          {min: 2, max: 12, message: "长度在 2 到 12 个字符", trigger: "blur"},
        ],
        jobDescription: [
          { required: true, message: "请输入职位描述", trigger: "blur" },
        ],
        jobSize: [
          { required: true, message: "请输入招聘人数", trigger: "blur" },
        ],
        city: [
            {required: true, message: "请选择工作城市", trigger: "blur"}
        ],
        workPlace: [
          { required: true, message: "请选择工作地点", trigger: "blur" },
        ],
        salaryLow: [
          { required: true, message: "请选择薪酬范围", trigger: "blur" },
        ],
        salaryHigh: [
          { required: true, message: "请选择薪酬范围", trigger: "blur" },
        ],
        expHigh: [
          { required: true, message: "请选择经验范围", trigger: "blur" },
        ],
        skillIds: [
          { required: true, message: "请选择工作技能", trigger: "blur" },
        ],
        // educationType: [
        //   { required: true, message: "请选择学历", trigger: "blur" },
        // ],
        expYear: [
          { required: true, message: "请选择工作经验", trigger: "blur" },
        ],
      },
      languageFlag: false, // 语种是否必填
      options: [],
      educationOptions: JSON.parse(sessionStorage.getItem("educationType")),
      expyearOptions: JSON.parse(sessionStorage.getItem("expYear")),
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      languageTYpeOption: [],
      expHighOptions: options.expHighOptions,
      expLowOptions: options.expLowOptions,
      releaseDialogVisible: false, //发布成功弹窗
      workDialogVisible: false, //工作地点弹窗
      workForm: {
        street: "",
        housenumber: "",
      },
      cityInfo: "",
      workskill: "",
    };
  },
  activated() {
    this.$api
      .jobdetail("get", { companyJobId: this.$route.query.id })
      .then((res) => {
        this.skillIds = [];
        this.skillNames = [];
        this.cityCodeed = [];
        res.data.skillIds.map((item) => {
          this.skillIds.push(item.itemValue);
          this.skillNames.push(item.itemText);
        });
        this.postruleForm.id = res.data.id;
        this.postruleForm.jobType = res.data.jobType;
        this.postruleForm.jobName = res.data.jobName;
        this.postruleForm.jobDescription = res.data.jobDescription;
        this.postruleForm.jobSize = res.data.jobSize;
        this.postruleForm.city = res.data.city;
        this.postruleForm.workPlace = res.data.workPlace;
        this.postruleForm.salaryLow = res.data.salaryLow;
        this.postruleForm.salaryHigh = res.data.salaryHigh;
        this.postruleForm.startDate = res.data.startDate;
        this.postruleForm.endDate = res.data.endDate;
        this.postruleForm.skillIds = res.data.skillIds;
        if (res.data.educationType.length > 0) {
          this.postruleForm.educationType = res.data.educationType[0].itemText;
        }

        this.postruleForm.expYear = res.data.expYear;
        this.postruleForm.expLow = res.data.expLow;
        this.postruleForm.expHigh = res.data.expHigh;
        this.postruleForm.honorCertNames = res.data.honorCertNames;
        this.postruleForm.languageType = res.data.languageType
          ? Number(res.data.languageType.itemValue)
          : "";
        this.languageFlag = this.postruleForm.languageType > 0;
        this.postruleForm.languageLevel = res.data.languageLevel
          ? Number(res.data.languageLevel.itemValue)
          : "";
        this.postruleForm.orderNum = res.data.orderNum;

        // console.log("Detail info test out ##### educationOptions.info = ", this.educationOptions);
        // console.log("Detail info test out ##### post rule form.info = ", this.postruleForm);
      });
  },
  computed: {
    languageOptions() {
      let arr = [];
      let languageType = JSON.parse(sessionStorage.getItem("languageType"));
      if (this.postruleForm.languageType) {
        if(this.postruleForm.languageType === '不填'){
          return arr;
        }
        languageType.map((item) => {
          if (this.postruleForm.languageType == item.id) {
            item.list.map((sub) => {
              let obj = {
                value: sub.id,
                label: sub.name,
              };
              arr.push(obj);
            });
          }
        });
      }
      return arr;
    },
  },
  created() {
    this.chishiaddress();
    this.getLanguage();
    if(this.skillList === null || this.skillList.length === 0) {
      // 技能列表获取
      let skills = JSON.parse(sessionStorage.getItem("skill"));
      console.log("@EditPosition ----- @activated ---- sessionStorage.getItem = ", skills);
      this.skillList = [];
      if (skills.itSkills !== null && skills.itSkills.length > 0){
        skills.itSkills.map((itSkill) =>{
          this.skillList.push(itSkill);
        });
      }
      if (skills.chipSkills !== null && skills.chipSkills.length > 0){
        skills.chipSkills.map((chipSkill) =>{
          this.skillList.push(chipSkill);
        });
      }
      // console.log("@EditPosition ----- @activated ---- this.skillList = ", this.skillList);
    }
  },
  methods: {
    projectSkill() {
      //项目技能
      let event  = this.skillType === 1 ? "chipSkillStatus" : this.skillType === 2 ? "nonTechStatus" : "itSkillStatus";
      Bus.$emit(event, {
        show: true,
        skillIds: this.skillIds,
        callback: (arr) => {
          // this.skillNames = [];
          // this.skillIds = [];
          this.clearSkillItemByType(this.skillType);
          // console.log("choose.callback --- ", event, " --- skill list, arr = ", arr);
          arr.map((item) => {
            if (this.skillIds.indexOf(item.value) === -1) {
              let skillItem = {
                value: item.value,
                label: item.label,
                code: item.code,
              };
              this.skillList.push(skillItem);
              this.skillNames.push(item.label);
              this.skillIds.push(item.value);
            }
          });
        },
      });
    },
    onSkillTypeChanged(typeId){
      // console.log("$change the skill type, value = ", typeId);
      this.skillType = typeId;
    },
    clearSkillItemByType(skillType) {
      let tempNames = [];
      let tempIds = [];
      let tempList = [];
      let codeStr = skillType === 1 ? "chip_skill" : skillType === 2 ? "non_tech_skill" : "skill";
      let mapList = this.skillList;
      mapList.map((skillItem) => {
        if (skillItem.code !== codeStr) {
          if (this.skillNames.indexOf(skillItem.label) !== -1) {
            tempNames.push(skillItem.label);
          }
          if (this.skillIds.indexOf(skillItem.value) !== -1) {
            tempIds.push(skillItem.value);
          }
          tempList.push(skillItem)
        }
      });
      this.skillNames = tempNames;
      this.skillIds = tempIds;
      this.skillList = tempList;
    },
    tipChange(value) {
      // console.log("----- tipChange ----- value = ", value);
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.cityInfo = tip;
          this.workForm.street = tip.district + tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.sb,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    onLanguageChanged(val){
      // console.log("onLanguageChanged ---- val = ", val)
      this.postruleForm.languageLevel = "";
      this.languageFlag = val > 0;
    },
    getLanguage() {
      //获取语言种类
      let languageType = JSON.parse(sessionStorage.getItem("languageType"));
      languageType.map((item) => {
        let obj = {
          value: item.id,
          label: item.name,
        };
        this.languageTYpeOption.push(obj);
      });
      let flag = false;
      this.languageTYpeOption.map( v => {
        if (v.label === "不填"){
          flag = true;
        }
      })
      if(!flag){
        this.languageTYpeOption.unshift({value:-1, label:'不填'});
      }
    },
    skillCommit() {
      //技能确定
      let secondData =
        this.skillList[this.skillFirstActive].list[this.skillSecondActive];
      if (this.skillIds.indexOf(secondData.id) == -1) {
        this.skillIds.push(secondData.id);
        this.skillNames.push(secondData.name);
      }
      if (
        this.skillList[this.skillFirstActive].list[this.skillSecondActive].list
          .length
      ) {
        let lastData =
          this.skillList[this.skillFirstActive].list[this.skillSecondActive]
            .list[this.skillLastActive];
        if (this.skillIds.indexOf(lastData.id) == -1) {
          this.skillIds.push(lastData.id);
          this.skillNames.push(lastData.name);
        }
      }
    },
    getSkillName() {
      //
      let skillNames = this.skillNames.join(",");
      return skillNames ? skillNames : "";
    },
    saveJob() {
      this.$refs.postInfoForm.validate((valid) => {
        if (valid) {
          // console.log("save the edit of the jobDetail ---- postValue = ", this.postruleForm);

          this.postruleForm.skillIds = this.skillIds.join(",");
          this.$refs.postNeedForm.validate((valid) => {
            if (valid) {
              // console.log("$saveJob #### this,post form = ", this.postruleForm, ", this cityInfo = ", this.cityInfo);
              // console.log("$saveJob #### this,post code = ", this.cityCodeed);
              // console.log("$saveJob #### test test = ", this.cityCodeed.indexOf(this.cityInfo.adcode));
              // console.log("$saveJob #### this,post code = ", this.postruleForm.workPlace.indexOf(this.postruleForm.city));
              let cityMatchFlag = false;
              if(this.cityCodeed && this.cityCodeed.length > 0){
                if(this.cityInfo !== "" && this.cityCodeed.indexOf(this.cityInfo.adcode)!==-1){
                  cityMatchFlag = true;
                } else if(this.cityInfo === "" && this.postruleForm.workPlace.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true;
                }
              } else {
                if(this.cityInfo !== "" && this.cityInfo.district.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true
                } else if (this.cityInfo === "" && this.postruleForm.workPlace.indexOf(this.postruleForm.city)===-1){
                  cityMatchFlag = true;
                }
              }
              if(cityMatchFlag){
                this.$message({
                  message: "已选工作城市与工作地址不匹配，请重新确认！",
                  type: "warning",
                });
                return false;
              }

              if (this.center.length > 0) {
                this.postruleForm["lng"] = this.center[0];
                this.postruleForm["lat"] = this.center[1];
              }

              if (this.postruleForm.educationType === '不限' || this.postruleForm.educationType === "") {
                this.postruleForm.educationType = "526";
              }

              if(this.postruleForm.languageType==='' || this.postruleForm.languageType === -1){
                this.postruleForm.languageType = "";
                this.postruleForm.languageLevel = "";
              } else {
                if(this.postruleForm.languageLevel === ""){
                  this.$message({
                    message: "已选有外语语种要求，请确认要求的语种等级！",
                    type: "warning",
                  });
                  return false;
                }
              }

              this.$api.savejob("post", this.postruleForm).then((res) => {
                // console.log("saveJob.res = ", res);
                if (res.code != 10200) {
                  this.$alert(res.message, "提示", {
                    confirmButtonText: "确定",
                  });
                } else {
                  this.releaseDialogVisible = true;
                  setTimeout(() => {
                    this.releaseDialogVisible = false;
                    this.$router.push("/induction");
                  }, 1000);
                }
              });
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    chishiaddress() {
      // console.log(cityDataes);
      this.options = cityDataes.cityData;
    },
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    handleChange(value) {
      //城市选择
      // console.log("城市选择 handleChange value = ", value);
      // console.log("城市选择 handleChange this.options = ", this.options);
      // this.postruleForm.cityCodeFull = value.toString();
      this.options.map((item) => {
        if (item.value == value[0]) {
          item.children.map((sub) => {
            if (sub.value == value[1]) {
              this.postruleForm.city = sub.label;
            }
          });
        }
      });
      // console.log(this.ruleForm.cityCodeFull);
      // console.log(this.ruleForm.cityCode);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log("submit form ----- work form = ", this.workForm, ", this tips = ", this.tips);
          // console.log("submit form ----- current city info  = ", this.cityInfo);
          this.postruleForm.workPlace = this.workForm.street + this.workForm.housenumber;
          this.postruleForm.workBuild = this.cityInfo.name;
          // console.log("submit form ----- form.city = ", this.postruleForm.city, ", cityCodeed = ", this.cityCodeed);
          if(this.cityCodeed.length>0){
            this.cityCodeed = [];
          }
          this.postruleForm.city = this.cityInfo.district;
          this.workDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style type="text/css">
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
.el-form-item__label {
  color: #111111;
  /* font-weight: 600; */
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>

<style lang="less" scoped>
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.addposition {
  background-color: #ffffff;
  padding: 30px;
  // height: 100%;
}
.a-tit {
  color: #111111;
  font-weight: 600;
  margin-bottom: 30px;
}
.from-box {
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }

  /deep/ .el-input-group__append,
  /deep/ .el-input-group__prepend {
    border-radius: 0 !important;
    background-color: #dddddd;
  }
}
/deep/ .el-button {
  border-radius: 0px;
}
.from-boxdown {
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }
  width: 900px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.release_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}

.skill-box-out {
  .skill-box {
    .sb-top {
      padding: 26px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f3f3f3;
      /deep/ .el-input__inner {
        border-radius: 0px;
        background-color: #f3f3f3;
        border: none;
      }
      /deep/ .el-button {
        border-radius: 0px;
        background: #00bcff;
        width: 100px;
        border: none;
      }
      .st-left {
        color: #000000;
        font-weight: 600;
      }
      .st-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin: 0 30px;
      }
      .st-right {
      }
    }
  }
}
.c-main {
  display: flex;

  min-height: 400px;
  .cm-left {
    width: 33.33%;
    background-color: #ffffff;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 18px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .t-active {
      color: #00bcff;
      font-weight: 600;
      background-color: #f8f7fc;
    }
    .t-active:before {
      position: absolute;
      content: "";
      width: 4px;
      height: 28px;
      // top: 0;
      background: #00bcff;
      left: 0px;
      transform: translate(0%, 0%);
    }
  }
  .cm-center {
    width: 33.33%;
    background-color: #f8f7fc;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .b-active {
      color: #00bcff;
    }
  }
  .cm-right {
    width: 33.33%;
    background-color: #f2f1f6;
    padding-top: 30px;
    .c-title {
      color: #111111;
      font-size: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-active {
      color: #00bcff;
    }
  }
}
</style>
